import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class DzikirPagiPetang extends BaseModel {
    fields =  {
      'category': {
        label: 'Category',
        type: 'enum',
        options: {
          'pagi': 'Pagi',
          'petang': 'Petang'
        },
        rules: 'required'
      },
      'doa': {
        type: 'editor',
        label: 'Doa teks arab',
        rules: 'required'
      },
      'arti_doa': {
        type: 'editor',
        label: 'Arti doa',
        rules: 'required'
      },
      'banyak_dibaca': {
        type: 'number',
        label: 'Berapa kali dibaca ?',
        rules: 'required'
      },
      'urutan': {
        type: 'number',
        label: 'Urutan',
        rules: 'required'
      },
    }
    endpoint = process.env.VUE_APP_API_URL + 'banners'
}